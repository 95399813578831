import React from 'react';
import PropTypes from 'prop-types';
import { TagStatementStyle, TagStatementSubheader } from './statement.css';

const TagStatement = ({ children, category, subheader, as = '', size, pic }) => {
    return (
        <TagStatementStyle pic={pic} >
            {category}
            <TagStatementSubheader>
                {subheader}
            </TagStatementSubheader>
        </TagStatementStyle>
    );
};

let textGenerator = (text) => {
    let textArray = text.split(" ");
    let firstHalf = textArray.slice(0, Math.floor(textArray.length / 2));
    let secondHalf = textArray.slice(Math.floor(textArray.length / 2), textArray.length)
    return (
        `${firstHalf} + <br/> + ${secondHalf}`
    )
}

TagStatement.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['large']),
};

export default TagStatement;